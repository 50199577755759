import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=11883587&scoped=true"
import script from "./Table.vue?vue&type=script&lang=js"
export * from "./Table.vue?vue&type=script&lang=js"
import style0 from "./Table.vue?vue&type=style&index=0&id=11883587&prod&scoped=true&lang=scss&ref=stylesheet%2Fscss"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11883587",
  null
  
)

export default component.exports