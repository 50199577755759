<template>
  <div class="GlobalTable">
    <GlobalTable ref="merchantTable" v-loading="loading" :columns="tableColumns1" :data="this.seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="账单流水号" slot="uniqNo" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.serType == '18'" style="color: red;">{{ row.uniqNo || "--" }}</span>
          <span v-else>{{ row.uniqNo || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="原始订单号" slot="orderNo" align="center">
        <template slot-scope="{ row }">
          <div style="color: #0981ff;cursor: pointer;" v-if="row.orderChannel == 'recycle'" @click="toOrderDetails(row)">
            <span style="border-bottom: 1px solid #0981ff">{{ row.orderNo }}</span>
          </div>
          <span v-else>{{ row.orderNo || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作人" slot="plopleaction" align="center">
        <template slot-scope="{ row }">
            <p v-if="(row.serType == '02'||row.serType == '01')&&!row.operateName">
                系统自动补贴
            </p>
            <p v-else>{{ row.operateName }}-{{ row.operateAccount }}</p>
        </template>
      </el-table-column>
      <el-table-column label="账单类型" slot="opt" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.serType == '01' ? '限额加价退单' : row.serType == '02' ? '订单限额加价' : row.serType == '03' ? '超额加价退单' : row.serType == '04' ? '订单超额加价' : row.serType == '05' ? '余额上账' : '余额下账'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="金额" slot="amount" align="center">
        <template slot-scope="{ row }">
          <span :style="row.serType == '05' || row.serType == '03'||row.serType == '01'? 'color:green' : 'color:red'">{{ row.amount &&
            (row.serType == '05' || row.serType == '03'||row.serType == '01') ? `+${row.amount}` : `-${row.amount}` }}</span>
        </template>
      </el-table-column>
      <el-table-column label="当时余额" slot="nowBalance" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.afterBalance || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="超额加价原因" slot="voucher" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.otherCause" >
            <el-tooltip class="item" effect="dark" :content="row.otherCause" placement="top">
             <p class="Remarks">{{row.otherCause}}</p>
            </el-tooltip>
            <span @click="seeVoucher(row.otherVoucher)" style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">查看凭证</span>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="账单备注" width="130rpx" slot="remark" align="center">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
            <div class="Remarks">
              {{ row.remark }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="查看凭证照片" :visible="imgShow" @close="imgClose"
      top="5%">
      <div class="img-box">
        <div class="img-tips-box">
          <div class="img-tips img-tips-choose">
            凭证照片
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList.length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList.length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList">
              <el-carousel trigger="click" height="80vh" @change="getindex">
                <el-carousel-item v-for="(item, ibdex) in DialogImgList" :key="ibdex">
                  <div class="sec_img">
                    <img :src="item" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      imgShow: false,
      DialogImgList: [],
      imgShowIndex: 0,
      loading: false,
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10
      },
      pageNum: 1,
      tableColumns1: [
        { slotName: "uniqNo" },
        { slotName: "opt" },
        { label: "账单时间", prop: "createTime" },
        { slotName: "orderNo" },
        { slotName: "plopleaction" },
        { slotName: "amount" },
        { slotName: "nowBalance" },
        { slotName: "quotePrice" },
        { slotName: "voucher" },
        { slotName: "remark" }
      ],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    },
    accountType(newVal) {
      this.SeachParams.opt = ""
      this.SeachParams.serType = ""
      this.handleCurrentChange()
    }
  },
  methods: {
    imgClose() {
      console.log(1);
      this.imgShow = false;
    },
    seeVoucher(data) {
      console.log(data);
      this.DialogImgList=[data]
      this.imgShow=true
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        uniqNo: this.SeachParams.uniqNo,
        orderNo: this.SeachParams.orderNo,
        serType: this.SeachParams.serType,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.getaccountPlatform(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.seachDataList.forEach(ele => {
            ele.merchantName = ele.merchantName || '--'
          });
          this.page.total = res.data.ipage.total;
          this.page.pageNum = res.data.ipage.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
      });
    },
    toOrderDetails(row) {
      this.$router.push({ path: "/RecallOrder/Details", query: { type: 'edit', id: row.orderNo } })
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  // 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}
.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}
  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }
}
</style>
