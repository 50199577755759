<template>
  <div class="class-list">
    <GlobalInfoBar title="平台支付账单" content="管理平台补贴加价账单" />
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="tip"
        style="margin-left:5px;font-size: 18px;color: #333;font-weight: bold;display: flex;align-items: center;">
        （当前平台账户余额：{{ platformBalance || 0 }}元）<img src="@/assets/images/refresh.png" alt="" style="width: 36px;height: 36px;"
          @click="handlebalance" class="rotate-image"></div>
      <div slot="filter">
        <From :accountType="accountType" />
      </div>
      <Table :accountType="accountType" @billNumber="billNumber" />
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
import _api from "@/utils/request";
export default {
  name: "class-list",
  components: {
    From,
    Table
  },
  data() {
    return {
      platformBalance:0,
      isRotate: false,//控制动画
      allDiffFund: 0,
      clickShow: '01',
      accountType: "merchant",
      billNumberObj: {},
    };
  },
  created() {
    if (this.$route.query.orderNo) {
      this.clickShow = '02'
      this.accountType = 'company'
    }
    console.log(this.$route.path);
    this.$store.commit('tagsView/ROUTER_PATH', this.$route.path)
  },
  mounted(){
    this.handlebalance()
  },
  methods: {
     // 查询余额
     handlebalance() {
      document.querySelector('.rotate-image').classList.toggle('rotate');
      let SeachParams = this.$store.state.tagsView.SeachParams
      _api.getaccountPlatform(SeachParams).then(res => {
          this.platformBalance=res.data.platformBalance||0
      });
    },
    // 获取当前总差异基金
    getAllDiffFund() {
      document.querySelector('.rotate-image').classList.toggle('rotate');
      _api.getAllDiffFund().then(res => {
        if (res.code === 1) {
          console.log(res, '当前总差异基金');
          this.allDiffFund = res.data
        }
      })
    },
    BtnClick(type) {
      this.$route.path.replace()
      if (type === 'active') {
        this.clickShow = '01'
        this.accountType = 'merchant'
      } else if (type === 'before') {
        this.clickShow = '02'
        this.accountType = 'company'
      } else if (type === '03') {
        this.clickShow = '03'
        this.accountType = 'companyDiffFund'
      }
    },
    billNumber(obj) {
      this.billNumberObj = obj
    },
    // 999以下的正常显示
    // 1000-9999显示1k，1.1k，9.9k
    // 10000以上的显示1w，9.9w，99w，999w
    numHandle(num) {
      if (num <= 9999) {
        return num
      } else {
        if ((num / 10000).toString().indexOf('.') < 0) {
          return (num / 10000) + 'w'
        } else {
          return this.formatDecimal(num / 10000, 1) + 'w'

        }
      }
    },
    formatDecimal(num, decimal) {
      num = num.toString()
      let index = num.indexOf('.')
      if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
      } else {
        num = num.substring(0)
      }
      num = parseFloat(num).toFixed(decimal).toString()
      if (num[num.indexOf('.') + 1] == 0) {
        num = num.substring(0, num.indexOf('.'))
      }
      return num
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.rotate-image {
  width: 36px;
  height: 36px;
  transition: transform 0.5s;
}

.rotate-image.rotate {
  animation: rotate360  1s linear;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.class-list {
  .button_top {
    cursor: pointer;
    margin-bottom: 20px;
    ;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 550px;
    height: 32px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      background: #0981FF;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }
}
</style>
